<template>
  <div>
    <div class="css_home">
      <div class="css_Navcont">
        <div style="font-size:40px">优制云</div>
        <div style="width:260px">
          <img class="css_img" src="@/assets/veiban-logo.png" />
        </div>
        <div @mouseover="showEwm(1)" @click="homepage(1)" class="Nav" :class="{ active:  isActive == 'Home'}">
          首页
        </div>
        <div @mouseover="showEwm(2)" @click="homepage(2)" class="Nav" :class="{ active: isActive == 'VeiBan' }">
          产品中心
          <img class="css_translateZ" src="@/assets/Shape.png" alt=""  />
        </div>
        <div @mouseover="showEwm(3)" @click="homepage(3)" class="Nav" :class="{ active: isActive  == 'programme' }">
          解决方案
          <img class="css_translateZ" src="@/assets/Shape.png" alt=""  />
        </div>
        <div @mouseover="showEwm(4)" @click="homepage(4)" class="Nav" :class="{ active: isActive  == 'Case' }">
          赋能案例
        </div>
        <div @mouseover="showEwm(5)" @click="homepage(5)" class="Nav" :class="{ active: isActive  == 'currency' }">
          伟班资讯
          <img class="css_translateZ" src="@/assets/Shape.png" alt="" />
        </div>
        <div @mouseover="showEwm(6)" @click="homepage(6)" class="Nav" :class="{ active: isActive== 'AboutUs' }">
          关于伟班
        </div>
        <el-button @click="onlog(1)" plain>注册伟班</el-button>
        <el-button @click="onlog(2)" type="primary">下载伟班</el-button>
        <el-button @click="onlog(3)" plain>企业登录</el-button>
      </div>
      <div v-if="iscss" class="css_Connectiondata" @mouseleave="showEwm(0)">
        <Mod v-if="ismod" class="css_mod" />
        <Solution v-if="isSolution" class="css_mod" />
        <Weibaninformation v-if="isWeibaninformation" class="css_mod" />
      </div>
    </div>
  </div>
</template>
<script>
import Mod from "@/components/Navigation/mod";
import Solution from "@/components/Navigation/Solution";
import Weibaninformation from "@/components/Navigation/Weibaninformation";

export default {
  components: {
    Mod,
    Solution,
    Weibaninformation,
  },
  data() {
    return {
      ismod: false,
      isSolution: false,
      isWeibaninformation: false,
      iscss: false,
      isActive: 'Home'
    };
  },
  created() {
    this.isActive = this.$route.name
  },
  methods: {
    openNewWindow(path) {
      const routeData = this.$router.resolve({
        path,
      });
      window.open(routeData.href, '_blank');
    },
    onlog(val) {
      if (val == 1) {
        this.openNewWindow('/Register')
        //  this.$router.push("/Register");
      } else if (val == 2) {
        this.openNewWindow('/Download')
        // this.$router.push("/Download");
      } else if (val == 3) {
        this.openNewWindow('/LogIn')
        // this.$router.push("/LogIn");
      }
    },
    homepage(val) {
      if (val == 1) {
        this.$router.push("/");
      }
      if (val == 2) {
        this.openNewWindow('/veiBan')
        // this.$router.push("/veiBan");
      }
      if (val == 3) {
        this.openNewWindow('/programme')
        // this.$router.push("/programme");
      }
      if (val == 4) {
        this.openNewWindow('/case')
        // this.$router.push("/case");
      }
      if (val == 5) {
        let id = "5";
        this.openNewWindow(`/currency/${id}`)
        // this.$router.push({
        //   path: `/currency/${id}`,
        // });
      }
      if (val == 6) {
        this.openNewWindow('/aboutUs')
        // this.$router.push("/aboutUs");
      }
    },
    showEwm(val) {
      // 2产品中心3解决方案5伟班资讯
      if (val == 2) {
        //產品中心
        this.iscss = true; //整个容器
        this.ismod = true; //产品中心显示
        this.isSolution = false; //解决方案
        this.isWeibaninformation = false; //伟班资讯
      }
      if (val == 3) {
        //解决方案
        this.iscss = true;
        this.ismod = false; //产品中心隐藏
        this.isSolution = true; //解决方案
        this.isWeibaninformation = false; //伟班资讯
      }
      if (val == 5) {
        this.iscss = true;
        this.isWeibaninformation = true; //伟班资讯
        this.ismod = false; //产品中心隐藏
        this.isSolution = false; //解决方案
      }
      if (val == 0) {
        this.iscss = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.css_translateZ {
  // transform: rotate(180deg);
  margin-left: 5px;
}
//   cursor: pointer;鼠标变手
.css_Connectiondata {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 99.1vw;
  height: auto;
  background: white;
  top: 101%;
  position: absolute;
  z-index: 1;
  text-align: left;
}
.css_Navcont {
  display: flex;
  align-items: center;
}

.css_home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid rgb(245, 245, 245);
  position: relative;
}
.css_img {
  width: 60%;
}
.Nav {
  height: 11vh;
  width: 8vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  color: #666666;
}
.Nav:hover {
  color: #1584ff;
  .css_translateZ {
    transition: all 0.2s ease-in;
    transform: rotate(180deg);
  }
}
.active{
  color: #1584ff;
}
</style>
