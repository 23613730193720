<template>
  <div class="footer">

    <section class="pc-more d-none d-lg-block" v-if="show">
      <div class="main">
        <h3>{{ title }}</h3>
        <span class="more-btn" @click="handleTryPc">免费试用</span>
      </div>
    </section>
    <section class="pc-more d-block d-lg-none" v-if="show">
      <div class="main">
        <h3>{{ title }}</h3>
        <span class="more-btn" @click="handleTry">免费试用</span>
      </div>
    </section>

    <section class="pc-more" v-if="join">
      <div class="main" >
        <div class="container" style="text-align: left;cursor: pointer;" @click="handleJob">
          <h3>加入我们</h3> 
          <h6>查看全部职位</h6> 
           <span class="el-icon-right" style="font-size: 26px;;"></span> 
        </div>
      </div>
    </section>
 


    <div class="css_home d-none d-lg-block" >
      <div class="css_cont container">
        <div class="css_information">
          <div class="css_informationtit">
            <h6>服务热线&nbsp;&nbsp;(周一至周五8:30~18:00)</h6>
          </div>
          <div class="css_information2">
            <div>0755-86016600/13808805396</div>
          </div>
          <div class="css_informationtit">
            <h6>公司邮箱:&nbsp;&nbsp;&nbsp;&nbsp;</h6>
          </div>
          <div class="css_information2">
            <div>cs@veiban.com</div>
          </div>
          <div class="css_information3">
            <!-- <img src="@/assets/newSite/program-s.png" />
            <img src="@/assets/newSite/talk-s.png" />
            <img src="@/assets/newSite/video-s.png" />
            <img src="@/assets/newSite/tiktok-s.png" /> -->
            <span>友情链接：</span>
            <span style="cursor: pointer;" @click="handleSite">优管网</span>
          </div>
        </div>
        <div class="css_labelcont">
          <p class="css_tite">热门产品</p>
          <div style="cursor:pointer;" @click="()=>{this.openNewWindow('/mes')}">智能制造MES</div>
          <div style="cursor:pointer;" @click="()=>{this.openNewWindow('/wms')}">仓储管理WMS</div>
          <div style="cursor:pointer;" @click="()=>{this.openNewWindow('/hrm')}">人力资源HRM</div>
          <div style="cursor:pointer;" @click="()=>{this.openNewWindow('/crm')}">客户关系CRM</div>
        </div>
        <!-- <div class="css_labelcont">
          <p class="css_tite">解决方案</p>
          <div style="cursor:pointer;" @click="()=>{this.$router.push('/smartMade')}">智能制造解决方案</div>
          <div style="cursor:pointer;" @click="()=>{this.$router.push('/smartOffice')}">智能办公解决方案</div>
          <div style="cursor:pointer;" @click="()=>{this.$router.push('/smartPark')}">智慧园区解决方案</div>
        </div> -->
        <div class="css_labelcont">
          <p class="css_tite">
            了解我们
          </p>
          <div style="cursor:pointer;" @click="()=>{this.openNewWindow('/contact?type=s')}">公司介绍</div>
          <div style="cursor:pointer;" @click="()=>{this.openNewWindow('/contact?type=f')}">发展历程</div>
          <div style="cursor:pointer;" @click="()=>{this.openNewWindow('/contact?type=l')}">联系我们</div>
        </div>
        <div class="css_labelcont">
          <p class="css_tite">行业动态</p>
          <div style="cursor: pointer;" @click="()=>{this.openNewWindow('/news?type=1')}">行业动态</div>
          <div style="cursor: pointer;" @click="()=>{this.openNewWindow('/news?type=0')}">公司动态</div>
        </div>
      </div>
      <div style="background-color: #323435; width: 100%">
        <div class="container links">
          <div class="copyright">{{ text }}</div>
          <div style="display: flex;align-items: center;">
             <img src="@/assets/filings.png" style="width: 20px;margin-right: 5px;"/>
             <a
              style="color: white;"
              rel="external nofollow"
              target="_blank"
              href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002001993"
            >
            粤公网安备44030002001993号</a
            >
          </div>
          <div class="copyright">
            <a
              style="color: white;padding-bottom:6px;"
              rel="external nofollow"
              target="_blank"
              href="https://beian.miit.gov.cn/"
            >
              粤ICP 备 2021111388号 电子公告服务规则</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-footer d-block d-lg-none" style="padding-bottom:64px;">
      <div class="css_information">
        <div class="mobile-tit">
          <h6>服务热线&nbsp;&nbsp;(周一至周五8:30~18:00)</h6>
        </div>
        <div class="mobile-key">
          <div>13808805396</div>
        </div>
        <div class="mobile-tit">
          <h6>公司邮箱:&nbsp;&nbsp;&nbsp;&nbsp;</h6>
        </div>
        <div class="mobile-key">
          <div>cs@veiban.com</div>
        </div>
        <div class="mobile-link">
          <!-- <div>
            <img src="@/assets/newSite/program-s.png" />
            <div class="key-name">小程序</div>
          </div>

          <div>
            <img src="@/assets/newSite/talk-s.png" />
            <div class="key-name">公众号</div>
          </div>

          <div>
            <img src="@/assets/newSite/video-s.png" />
            <div class="key-name">视频号</div>
          </div>

          <div>
            <img src="@/assets/newSite/tiktok-s.png" />
            <div class="key-name">抖音号</div>
          </div> -->

          <div @click="handleSite">
            <img src="@/assets/newSite/shopping-s.png" />
            <div class="key-name">优管网</div>
          </div>
        </div>
      </div>

      <div class="mobile-fot">
        <div class="link">
          <div class="copyright">{{ text }}</div>
          <div class="copyright">
            <a
              style="color: white"
              rel="external nofollow"
              target="_blank"
              href="https://beian.miit.gov.cn/"
            >
              粤ICP 备 2021111388号 电子公告服务规则</a
            >
          </div>
          <div>
             <img src="@/assets/filings.png" style="width: 20px;margin-right: 5px;"/>
             <a
              style="color: white;"
              rel="external nofollow"
              target="_blank"
              href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002001993"
            >
            粤公网安备44030002001993号</a
            >
          </div>
        </div>
      </div>
    </div>


    <div class="vueMdelBox" :hidden="!vueShowModel2">
      <div class="vueContant">
        <div class="vueTitle" @click="confirm">
          <span class="el el-icon-close"></span>
        </div>
        <div class="main">
          <img style="width: 100px" src="../assets/newSite/site.png" />
          <div style="margin-top:10px;">请用电脑打开优制云官网</div>
          <div>注册免费试用产品</div>
        </div>
      </div>
      <!-- 背景黑色蒙版 -->
      <div
        class="vueBgdCol"
        :hidden="!vueShowModel2"
        @click="vueShowModel2 = false"
      ></div>
    </div>

  </div>
</template>
  <script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    join: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "进一步了解优制云产品及行业解决方案",
    },
  },
  data() {
    return {
      // text: "Copyright &#169 2021 深圳优制云工业互联网有限公司 版权所有",
      text: "Copyright© 2024 深圳优制云工业互联网有限公司 版权所有",
      vueShowModel2: false,
    };
  },
  methods: {
    openNewWindow(path) {
      const routeData = this.$router.resolve({
        path,
      });
      window.open(routeData.href, "_blank");
    },
    handleJob() {
      window.open(
        "https://www.zhipin.com/gongsi/job/651e1afd7afd70551nJ40tm1EFE~.html?ka=company-jobs"
      );
    },
    handleTryPc() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleTry() {
      this.vueShowModel2 = true;
    },
    confirm() {
      this.vueShowModel2 = false;
    },

    handleSite() {
      window.open("https://epipe.cn");
    },

    pageJump(name_, index) {
      if (index > 0) {
        // debugger
        let e = $(".nav > li > a")[index];
        $(e).siblings().removeClass("activeColor");
        $(e).addClass("activeColor");
      }
      this.$router.push({
        name: name_,
      });
    },
  },
};
</script>
  <style lang="less" scoped>
.vueBgdCol {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 19;
  -moz-opacity: 0.6;
  opacity: 0.6;
  filter: alpha(opacity=88);
}

.vueContant {
  padding: 10px 0px 0px 0px;
  position: fixed;
  top: 36%;
  left: 50%;
  width: 80%;
  min-height: 220px;
  margin-left: -40%;
  background-color: white;
  z-index: 20;
  overflow: auto;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  .vueTitle {
    text-align: right;
  }
}

.pc-more {
  .main {
    height: 240px;
    background: url("../assets//newSite/more.png") no-repeat center;
    background-size: cover;
    box-sizing: border-box;
    padding: 60px;
    color: white;
    .more-btn {
      margin-top: 20px;
      display: inline-block;
      width: 130px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border: 1px solid white;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.mobile-footer {
  padding: 20px 0;
  .mobile-tit {
    color: #333;
    font-size: 14px;
    font-family: "思源黑体";
    text-align: center;
  }

  .mobile-key {
    margin: 10px 0 15px 0;
    font-size: 14px;
    color: #1484ff;
    text-align: center;
  }

  .mobile-link {
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
    img {
      width: 70px;
      height: 70px;
      &:hover {
        cursor: pointer;
      }
    }
    .key-name {
      color: #666;
      font-size: 12px;
      margin-top: -10px;
    }
  }

  .mobile-fot {
    background-color: #323435;
    width: 100%;
    margin-top: 10px;
    .link {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      color: white;
      font-size: 13px;
    }
  }
}
.css_informationtit {
  color: #333;
  font-size: 16px;
  font-family: "思源黑体";
  text-align: left;
}
.css_information2 {
  margin: 15px 0 30px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #1484ff;
}
.css_information2 > div {
  font-size: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  margin-right: 10px;
}
.css_information3 {
  margin-top: 30px;
  display: flex;
  font-size: 14px;
  color: #666666 !important;
  //   margin-left: -20px;
  align-items: center;
  img {
    // transform: scale(0.5)
    width: 70px;
    height: 70px;
    margin-left: -20px;
    &:hover {
      cursor: pointer;
    }
  }
}
.css_information3 > div {
  font-size: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  color: #adb5c0;
}
.css_information2 > span {
  font-size: 30px;
  font-family: SourceHanSansCN, SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.css_cont {
  //   width: 1200px;
  padding: 40px 0px;
  //   border: 1px solid red;
  display: flex;
  justify-content: space-between;
}
.links {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  color: white;
  font-size: 14px;
}
.css_tite {
  font-size: 16px;
  font-family: "思源黑体";
  font-weight: 700;
  text-align: left;
  color: #333;
}

.css_labelcont > div {
  margin-bottom: 20px;
  font-size: 14px;
  font-family: SourceHanSansCN, SourceHanSansCN-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
  //   width: 100px;
}
.css_home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
  